import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { register } from 'register-service-worker';

import {Notify, Quasar} from 'quasar'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';


import {loadFonts} from './plugins/webfontloader'
import Vue from "@vue/cli-plugin-eslint/eslintOptions";

// import VueSocketIOExt from 'vue-socket.io-extended';
// import io from 'socket.io-client';
//
// const socket = io('http://localhost:8083');



register('/service-worker.js', {
    ready() {
        console.log('Service worker is active.');
    },
    registered() {
        console.log('Service worker has been registered.');
    },
    cached() {
        console.log('Content has been cached for offline use.');
    },
    updatefound() {
        console.log('New content is downloading.');
    },
    updated() {
        console.log('New content is available; please refresh.');
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
        console.error('Error during service worker registration:', error);
    },
});



Vue.config.lang = 'fa'
Vue.config.rtl = true
loadFonts()

Sentry.init({
    Vue,
    dsn: 'YOUR_SENTRY_DSN',
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});


createApp(App)
    .use(router)
    .use(store)
    // .use(VueSocketIOExt, socket)
    .use(vuetify)
    .use(Quasar, {
        plugins: {
            Notify
        }
    })
    .use(Quasar)
    .mount('#app')


// import SockJS from 'sockjs-client'
// import {Client} from '@stomp/stompjs'
//
// const socket = new SockJS('http://192.168.222.240:1020/ws')
// export const stompClient = new Client({
//     webSocketFactory: () => socket,
//     reconnectDelay: 5000,
//     heartbeatIncoming: 4000,
//     heartbeatOutgoing: 4000,
// })
//
//
// stompClient.onConnect = (frame) => {
//     console.log('WebSocket connection established')
//     console.log('WebSocket connection established', frame)
// }
//
// stompClient.onStompError = (frame) => {
//     console.error('Stomp error', frame)
// }
