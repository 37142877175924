<template>
  <v-locale-provider rtl>


    <v-container>

      <v-app-bar app fixed height="60" elevation="0" style="background: #FFF6EE;">
        <v-row justify="center">
          <v-col class="logoNameShapeHeight d-flex justify-center align-center logo-custom-font" cols="7" sm="8" md="6"
                 xs="10">
            <span style="font-size: 30px;font-weight: bold;">کافه عمارت سران</span>
          </v-col>

        </v-row>

      </v-app-bar>
    </v-container>
  </v-locale-provider>
</template>

<script>
export default {
  name: "EggDesign",
};
</script>

<style>

.logoNameShapeHeight {
  height: 50px;
  //margin-top: 5px;
}

@font-face {
  font-family: 'logoFont';
  src: url('/src/assets/aviny.ttf') format('opentype');
}

.logo-custom-font {
  font-family: 'logoFont', sans-serif; /* نام فونت خود را در اینجا قرار دهید */
}

</style>
