<template dir="rtl">
  <v-locale-provider rtl>
    <Header/>

    <v-app-bar app fixed height="90" color="#FFF6EE" elevation="0">
      <v-img class="logo-img justify-center align-center" :width="100" :height="100"
             :src="require(`@/assets/he.png`)" style="margin-top: -12px;"></v-img>
    </v-app-bar>

    <v-app-bar app fixed height="100" color="#FFF6EE" elevation="0">
      <div style="width: 100%; overflow-x: auto; white-space: nowrap;">
        <div style="display: inline-flex;">
          <v-card v-for="(category, index) in categories" :key="index" shaped width="90" height="100"
                  class="v-cardCategory shapeCategory flex-column align-center" elevation="0"
                  @click="getSelectedCategoryFoods(category.id)"
                  style="padding-bottom: 4px; margin-left: 5px; margin-right: 5px; overflow: visible; display: inline-block;">
            <v-img class="logo-img justify-center align-center" :width="45" aspect-ratio="16/9" cover
                   :src="require(`@/assets/${category.imgSrc}`)" style="margin-top: 10px;"></v-img>
            <v-card-text class="text-center category-text">{{ category.name }}</v-card-text>
            <div class="d-flex justify-center align-center" style="width: 100%; margin-top: -10px;">
              <div v-if="isSelectedCategory(category.id)"
                   style="width: 40px; height: 4px; background-color: #D99756; border-radius: 2px; box-shadow: 0 2px 4px rgba(0,0,0,0.2);"></div>
              <div v-if="!isSelectedCategory(category.id)"
                   style="width: 40px; height: 4px; background-color: #FFF6EE;"></div>
            </div>
          </v-card>
        </div>
      </div>
    </v-app-bar>


    <v-app app fixed color="#FFF6EE">
      <v-container class="foods-container" style="margin-top: -34px;margin-bottom: 45px;">
        <v-row>
          <v-col v-for="(food, index) in getFoods()" :key="index" cols="12" md="6" lg="4" class="mb-0">
            <v-card class="item-row d-flex align-center">
              <v-row no-gutters>
                <v-col cols="3" class="d-flex justify-center align-center">
                  <v-img :src="require(`@/assets/${food.image}`)" style="border-radius: 35px;" max-width="90"
                         max-height="90" class="ml-2"></v-img>
                </v-col>
                <v-col cols="5">
                  <v-card-title class="title-product"
                                style="color: #301B08;font-family: 'logoFont', sans-serif;font-size: 26px">{{
                      food.name
                    }}
                  </v-card-title>
                  <div style="color: #301B08;font-family: 'logoFont', sans-serif;font-size: 23px;margin-right: 5px">
                    {{ convertNumberToFarsi(food.price, true) + " " }}
                    <span style="font-size: 15px;">تومان</span>
                  </div>

                </v-col>
                <v-col cols="4" class="d-flex flex-column justify-center align-center">
                  <div class="d-flex align-center justify-center">
                    <v-btn icon @click="removeFromCart(food.id)" class="v-btn-order mr-2"
                           style="width: 32px;height: 32px">
                      <v-icon>
                        <v-img :src="require(`@/assets/minus.svg`)" aspect-ratio="1" class="rounded-img"></v-img>
                      </v-icon>
                    </v-btn>

                    <span style="color: #CCC4C1; margin: 0 8px;">{{
                        convertNumberToFarsi(getQuantityInCart(food.id), false)
                      }}</span>


                    <v-btn icon @click="addToCart(food.id)" class="v-btn-order ml-2"
                           style="width: 32px;height: 32px;background: #F18526;color: #D9C4A9">
                      <v-icon>
                        <v-img :src="require(`@/assets/plus.svg`)" aspect-ratio="1" class="rounded-img"></v-img>
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>

              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <CurvedBottomNavigation
        style="font-size: 25px"
        foreground-color='#FFF6EE'
        badge-color='#F18526'
        background-color='#532C24'
        icon-color='#FFF6EE'
        :options="computedOptions"
        v-model="selected"
        @click="handleNavSelected(selected)"
    >
      <v-icon>mdi-account</v-icon>
    </CurvedBottomNavigation>

    <!--    <div id="cart-logo" v-if="getCountCart() > 0" @click="dialog = true">-->
    <!--      <v-btn icon class="cart-button elevation-anim">-->
    <!--        <v-icon>mdi-cart</v-icon>-->
    <!--        <span class="cart-count"><span>{{ getCountCart() }}</span></span>-->
    <!--      </v-btn>-->
    <!--    </div>-->


    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition"
              style="overflow-y: auto;z-index: 9999;">
      <v-app-bar app fixed height="48" color="#FADEC1" elevation="0"
                 style="position: absolute; font-family: 'logoFont', sans-serif; top: 0; bottom: auto; width: 100%;">
        <v-toolbar-title>
          سبد خرید
        </v-toolbar-title>
      </v-app-bar>


      <v-container class="ordersList"
                   style="margin-top: 45px;overflow-y: auto; margin-bottom: 239px;height: 100%; width: 100%; max-width: none;">
        <v-row>
          <v-col v-for="(food, index) in cart" :key="index" cols="12" md="6" lg="4" class="mb-0">
            <v-card class="item-row d-flex align-center">
              <v-row no-gutters>
                <v-col cols="3" class="d-flex justify-center align-center">
                  <v-img :src="require(`@/assets/${food.image}`)" style="border-radius: 35px;" max-width="90"
                         max-height="90" class="ml-2"></v-img>
                </v-col>
                <v-col cols="5">
                  <v-card-title class="title-product"
                                style="color: #301B08;font-family: 'logoFont', sans-serif;font-size: 26px">{{
                      food.name
                    }}
                  </v-card-title>
                  <div style="color: #301B08;font-family: 'logoFont', sans-serif;font-size: 23px;margin-right: 5px">
                    {{ convertNumberToFarsi(food.price, true) + " " }}
                    <span style="font-size: 15px;">تومان</span>
                  </div>

                </v-col>
                <v-col cols="4" class="d-flex flex-column justify-center align-center">
                  <div class="d-flex align-center justify-center">
                    <v-btn icon @click="removeFromCart(food.pId)" class="v-btn-order mr-2"
                           style="width: 32px;height: 32px">
                      <v-icon>
                        <v-img :src="require(`@/assets/minus.svg`)" aspect-ratio="1" class="rounded-img"></v-img>
                      </v-icon>
                    </v-btn>

                    <span style="color: #CCC4C1; margin: 0 8px;">{{
                        convertNumberToFarsi(getQuantityInCartOrder(food.pId), false)
                      }}</span>


                    <v-btn icon @click="addToCart(food.pId)" class="v-btn-order ml-2"
                           style="width: 32px;height: 32px;background: #F18526;color: #D9C4A9">
                      <v-icon>
                        <v-img :src="require(`@/assets/plus.svg`)" aspect-ratio="1" class="rounded-img"></v-img>
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>

              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <v-container class="confirm-order-container"
                   style="background-color: #f5f5f5; padding: 20px;margin-bottom: 56px; width: 100%; max-width: none;z-index: 9999;">
        <v-row>
          <v-col cols="12">
            <v-text-field label="پیام کاربر" v-model="userMessage" outlined dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div style="font-size: 22px; font-family: 'logoFont', sans-serif;margin-bottom: 10px;"> مبلغ کل:
              {{ convertNumberToFarsi(totalPrice, true) + " تومان " }}
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="6" class="text-center">
            <v-btn color="success" dark large @click="confirmOrder" block>تایید سفارش</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn color="error" dark large @click="dialog= false" block>لغو سفارش</v-btn>
          </v-col>
        </v-row>
      </v-container>


    </v-dialog>


  </v-locale-provider>
</template>

<script>
import {defineComponent} from 'vue';
import {CurvedBottomNavigation} from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import '@mdi/font/css/materialdesignicons.css'
// Components
import Header from '../components/ClassicHeader.vue';
import * as persianHelpers from "persian-helpers";

export default defineComponent({
  data() {
    return {
      dialog: false,
      alert: false,
      timer: null,
      selectedCategory: 0,
      selectedCategoryId: null,
      totalPrice: 0,
      categories: [
        {id: 1, imgSrc: 'tea.svg', name: 'نوشیدنی گرم'},
        {id: 2, imgSrc: 'cold.svg', name: 'نوشیدنی سرد'},
        {id: 3, imgSrc: 'gal.svg', name: 'قلیان'},
        {id: 4, imgSrc: 'pizza.svg', name: 'پیتزا'},
      ],
      foods: [
        {
          id: 1,
          pId: 1,
          categoryId: 1,
          availableCount: 6,
          cartCount: 0,
          name: 'قهوه',
          price: 20000,
          image: 'coffe.jpg'
        },
        {
          id: 2,
          pId: 2,
          categoryId: 1,
          availableCount: 2,
          cartCount: 0,
          name: 'کاپاچینو',
          price: 15000,
          image: 'esperoso.jpg'
        },
        {
          id: 3,
          pId: 3,
          categoryId: 1,
          availableCount: 3,
          cartCount: 0,
          name: 'اسپشیال',
          price: 10000,
          image: 'espshial.jpg'
        },
        {
          id: 4,
          pId: 4,
          categoryId: 1,
          availableCount: 5,
          cartCount: 0,
          name: 'موکاچینو',
          price: 10000,
          image: 'mokachino.jpg'
        },
        {
          id: 5,
          pId: 5,
          categoryId: 1,
          availableCount: 6,
          cartCount: 0,
          name: 'قهوه ترک',
          price: 10000,
          image: 'gturk.jpg'
        },
        {id: 6, pId: 6, categoryId: 4, availableCount: 5, cartCount: 0, name: 'منگو', price: 10000, image: 'mngo.jpg'},
        {id: 7, pId: 7, categoryId: 4, availableCount: 3, cartCount: 0, name: 'ماما', price: 10000, image: 'mama.jpg'},
        {id: 8, pId: 8, categoryId: 4, availableCount: 4, cartCount: 0, name: 'موز', price: 10000, image: 'moz.jpg'}
      ],
      cart: [],
      isCartVisible: false,
      selected: 2,
      prevNavSelectedId: 2,
      cartCount: 0,
      options: [
        {
          id: 1,
          icon: "mdi mdi-comment",
        },
        {
          id: 2,
          icon: "mdi mdi-cart",
          badge: this.cartCount
        }, {
          id: 3,
          icon: "mdi mdi-music",
        },
      ],
    }
  },
  computed: {
    computedOptions() {
      return this.options.map(option => {
        if (option.id === 2) {
          return {
            ...option,
            badge: this.getCountCart()
          };
        }
        return option;
      });
    }
  },
  methods: {
    isSelectedCategory(categoryId) {
      return this.selectedCategoryId === categoryId;
    },
    resetAlert() {
      this.alert = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.alert = false;
      }, 4000);
    },
    getCartFoods() {
      this.cart.forEach(i => {
        console.log("", i.id);
      });

    },
    getSelectedCategoryFoods(categoryId) {
      this.selectedCategoryId = categoryId;
      this.changeCategoryId(categoryId);
    },
    changeCategoryId(newCategoryId) {
      this.selectedCategory = newCategoryId;
    },
    getFoods() {
      if (this.selectedCategory === 0) {
        return this.foods;
      } else {
        return this.foods.filter(food => food.categoryId === this.selectedCategory);
      }
    }
    ,
    handleNavSelected(navId) {
      console.log("nav id : ", navId);
      if (this.selected === 2 && this.getCountCart() > 0 && this.prevNavSelectedId === navId) {
        this.dialog = true;
      }
      this.prevNavSelectedId = navId;
    },
    addToCart(id) {
      const food = this.foods.find(item => item.id === id);
      console.log("addToCart : " + food.name + " id: " + id)
      const existingCartItemIndex = this.cart.findIndex(item => item.foodId === id);
      if (existingCartItemIndex !== -1) {
        this.cart[existingCartItemIndex].cartCount++;
      } else {
        this.cart.push({
          id: this.cart.length + 1,
          foodId: food.id,
          pId: food.pId,
          categoryId: food.categoryId,
          cartCount: 1,
          name: food.name,
          price: food.price,
          image: food.image
        });
      }
      // if (this.dialog === true) {
      this.getTotalPrice();
      // }
      console.log("cart : ", this.cart)
    },
    removeFromCart(id) {
      const existingCartItemIndex = this.cart.findIndex(item => item.foodId === id);

      if (existingCartItemIndex !== -1) {
        if (this.cart[existingCartItemIndex].cartCount > 1) {
          this.cart[existingCartItemIndex].cartCount--;
        } else {
          this.cart.splice(existingCartItemIndex, 1);
        }
      }
      if (this.dialog === true && this.cart.length === 0) {
        this.dialog = false;
      }
      // if (this.dialog === true) {
      this.getTotalPrice();
      // }
    },
    convertNumberToFarsi(price, sep) {
      return persianHelpers.convertDigits(price, {separator: sep});
    },
    getQuantityInCart(foodId) {

      const cartItem = this.cart.find(item => item.foodId === foodId);
      if (cartItem != null) {
        return cartItem.cartCount;
      } else {
        return 0;
      }


    }, getQuantityInCartOrder(pId, name) {
      console.log("foodId : ", pId, " name : ", name)

      const cartItem = this.cart.find(item => item.pId === pId);
      if (cartItem != null) {
        return cartItem.cartCount;
      } else {
        return 0;
      }

    },
    getCountCart() {
      if (!this.cart || this.cart.length === 0) {
        return 0;
      } else {
        let cartCount = 0;
        for (const item of this.cart) {
          cartCount = cartCount + this.getQuantityInCart(item.foodId);
        }
        return cartCount;
        // return this.cart.length;
      }
    },
    getTotalPrice() {
      console.log("getTotalPrice : ")
      console.log("getTotalPrice : ", this.cart)
      if (!this.cart || this.cart.length === 0) {
        this.totalPrice = 0;
        return 0;
      } else {
        let totalPrice = 0;
        for (const item of this.cart) {
          let cartCount = this.getQuantityInCart(item.foodId);
          let calPrice = cartCount * item.price;
          totalPrice = totalPrice + calPrice;

        }
        this.totalPrice = totalPrice;
        return totalPrice;
      }
    }
  },
  name: 'HeaderApp',
  components: {
    Header,
    CurvedBottomNavigation,
  },
});
</script>

<style>
.item-row {
  background: #FADEC1;
  border-radius: 30px;
}

.category-text {
  margin-top: -10px;
  font-size: 20px;
  font-family: 'logoFont', sans-serif;

}

@font-face {
  font-family: 'logoFont';
  src: url('/src/assets/avinytwo.ttf') format('opentype');
}


.confirm-order-container {
  background-color: #f5f5f5;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.shapeCategory {
  background: #FFF6EE;
  //margin-right: 4px;
  //margin-left: 4px;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;

}

.title-product {
  margin-right: -10px;
  text-align: right;
}

.center-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-btns .v-btn-order {
  margin: 0 10px;
}

.rounded-img {
  border-radius: 60px;
}


.v-cardCategory {
  display: inline-block;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.logo-img {
  margin: auto;
  align-self: center;
}

.foods-container {
  background: #FFF6EE;
  overflow-y: auto;
}

.ordersList {
  background: #FFF6EE;
  margin-top: 1px;

}


</style>

<style scoped>
.cart-button {
  position: fixed;
  left: 10px;
  bottom: 70px;
  z-index: 999;
}

.cart-count {
  background-color: red; /* رنگ پس زمینه */
  color: white; /* رنگ متن */
  border-radius: 50%; /* شکل گرد */
  padding: 5px; /* فاصله از مرزهای داخلی */
  padding-left: 7px;
  font-size: 12px; /* اندازه فونت */
  width: 20px; /* عرض */
  height: 20px; /* ارتفاع */
  text-align: center; /* متن وسط قرار بگیرد */
  position: absolute; /* حالت مطلق */
  top: -10px; /* تنظیم موقعیت بالا */
  right: -10px; /* تنظیم موقعیت راست */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; /* تنظیم خط داخلی به عرض برای متن وسط شده */
  transform: translateX(-50%); /* جابه‌جایی به سمت چپ */
}

.elevation-anim {
  animation: pulse-shadow 2s infinite alternate;
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgb(201, 112, 32);
  }
  100% {
    box-shadow: 0 0 10px 5px rgb(253, 148, 52);
  }
}
</style>
