<template dir="rtl">
  <Menu dir="rtl"/>
  <Hello dir="rtl"/>

</template>

<script>
import {defineComponent} from 'vue';

// Components
import Menu from '../components/MenuViewComponent.vue';


export default defineComponent({
  name: 'HomeView',

  components: {
    Menu,
  },
});
</script>
